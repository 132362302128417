var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "goods-return-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "退货单",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _vm.afterOrder ? _c('div', {
    staticClass: "app-main-container"
  }, [_c('div', {
    staticClass: "goods-return-content"
  }, [_c('div', {
    staticClass: "goods-return-content__tips"
  }, [_vm._v(" 提示：往左边滑动可以删除商品 ")]), _c('div', {
    staticClass: "goods-return-content__client"
  }, [_c('van-cell', {
    attrs: {
      "title": _vm.afterOrder.client.name,
      "center": "",
      "value": '合计：￥' + _vm.totalAmount
    }
  })], 1), _vm._l(_vm.afterOrder.goodses, function (goods, index) {
    return _c('van-swipe-cell', {
      key: index,
      staticClass: "goods-return-item",
      scopedSlots: _vm._u([{
        key: "right",
        fn: function fn() {
          return [_c('van-button', {
            attrs: {
              "square": "",
              "block": "",
              "text": "删除",
              "type": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.handleDelete(goods);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('van-cell', {
      attrs: {
        "title": '备注：' + goods.remark
      }
    }), _c('div', {
      staticClass: "goods-return-content__cell"
    }, [_c('div', {
      staticClass: "goods-return-content__cell__image"
    }, [_c('van-image', {
      attrs: {
        "width": "2.26666rem",
        "height": "2.26666rem",
        "src": goods.image
      }
    })], 1), _c('div', {
      staticClass: "goods-return-content__cell__info"
    }, [_c('div', {
      staticClass: "goods-return-content__cell__info__name"
    }, [_vm._v(_vm._s(goods.name))]), _c('div', {
      staticClass: "goods-return-content__cell__info__size"
    }, [_c('span', [_vm._v("规格：1*" + _vm._s(goods.size))])]), _c('div', {
      staticClass: "goods-return-content__cell__info__price"
    }, [_vm._v(" 单价："), _c('span', [_vm._v("￥" + _vm._s(goods.price))])]), _c('div', {
      staticClass: "goods-return-content__cell__info__amount"
    }, [_vm._v(" 合计："), _c('span', [_vm._v("￥" + _vm._s(goods.amount))])])])]), goods.good_volume ? _c('van-cell', {
      attrs: {
        "title": "完好的"
      }
    }, [[goods.good_large_volume > 0 ? _c('span', [_vm._v(_vm._s(goods.good_large_volume) + _vm._s(goods.large_unit))]) : _vm._e(), goods.good_large_volume && goods.good_least_volume ? _c('span', [_vm._v(" + ")]) : _vm._e(), goods.good_least_volume > 0 ? _c('span', [_vm._v(_vm._s(goods.good_least_volume) + _vm._s(goods.least_unit))]) : _vm._e()]], 2) : _vm._e(), goods.bad_volume ? _c('van-cell', {
      attrs: {
        "title": "损坏的"
      }
    }, [_c('div', [goods.bad_large_volume > 0 ? _c('span', [_vm._v(_vm._s(goods.bad_large_volume) + _vm._s(goods.large_unit))]) : _vm._e(), goods.bad_large_volume && goods.bad_least_volume ? _c('span', [_vm._v(" + ")]) : _vm._e(), goods.bad_least_volume > 0 ? _c('span', [_vm._v(_vm._s(goods.bad_least_volume) + _vm._s(goods.least_unit))]) : _vm._e()])]) : _vm._e()], 1);
  })], 2)]) : _vm._e(), _c('div', {
    staticClass: "fixed-bottom-btn"
  }, [_vm.afterOrder ? _c('van-button', {
    attrs: {
      "square": "",
      "block": "",
      "disabled": !_vm.afterOrder
    },
    on: {
      "click": function click($event) {
        _vm.showActionSheet = true;
      }
    }
  }, [_vm._v("添加商品")]) : _c('van-button', {
    attrs: {
      "square": "",
      "block": ""
    },
    on: {
      "click": _vm.createAfterOrder
    }
  }, [_vm._v("创建退货单")])], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.handleSelect
    },
    model: {
      value: _vm.showActionSheet,
      callback: function callback($$v) {
        _vm.showActionSheet = $$v;
      },
      expression: "showActionSheet"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
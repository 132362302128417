import request from '@/utils/request'

export default {

  create(data) {
    return request({
      method: 'post',
      url: 'goods-return/create',
      data
    })
  },
  list(params) {
    return request({
      method: 'get',
      url: 'goods-return',
      params
    })
  },
  detail(params) {
    return request({
      method: 'get',
      url: 'goods-return/detail',
      params
    })
  },
  add(data) {
    return request({
      method: 'post',
      url: 'goods-return',
      data
    })
  },
  delete(data) {
    return request({
      method: 'delete',
      url: `goods-return/${data.after_order_id}/goods/${data.after_goods_id}`
    })
  },
  order(params) {
    return request({
      method: 'get',
      url: 'goods-return/order',
      params
    })
  },
  cancelOrder(data) {
    return request({
      method: 'post',
      url: 'goods-return/cancel-order',
      data
    })
  }

}

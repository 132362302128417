<template>
  <div class="goods-return-container">
    <my-nav-bar
      title="退货单"
      left-text="返回"
      left-arrow
    />
    <div v-if="afterOrder" class="app-main-container">
      <div class="goods-return-content">
        <div class="goods-return-content__tips">
          提示：往左边滑动可以删除商品
        </div>
        <div class="goods-return-content__client">
          <van-cell :title="afterOrder.client.name" center :value="'合计：￥' + totalAmount" />
        </div>
        <van-swipe-cell v-for="(goods, index) in afterOrder.goodses" :key="index" class="goods-return-item">
          <van-cell :title="'备注：' + goods.remark" />
          <div class="goods-return-content__cell">
            <div class="goods-return-content__cell__image">
              <van-image width="2.26666rem" height="2.26666rem" :src="goods.image" />
            </div>
            <div class="goods-return-content__cell__info">
              <div class="goods-return-content__cell__info__name">{{ goods.name }}</div>
              <div class="goods-return-content__cell__info__size">
                <span>规格：1*{{ goods.size }}</span>
              </div>
              <div class="goods-return-content__cell__info__price">
                单价：<span>￥{{ goods.price }}</span>
              </div>
              <div class="goods-return-content__cell__info__amount">
                合计：<span>￥{{ goods.amount }}</span>
              </div>
            </div>
          </div>
          <van-cell v-if="goods.good_volume" title="完好的">
            <template>
              <span v-if="goods.good_large_volume > 0">{{ goods.good_large_volume }}{{ goods.large_unit }}</span>
              <span v-if="goods.good_large_volume && goods.good_least_volume"> + </span>
              <span v-if="goods.good_least_volume > 0">{{ goods.good_least_volume }}{{ goods.least_unit }}</span>
            </template>
          </van-cell>
          <van-cell v-if="goods.bad_volume" title="损坏的">
            <div>
              <span v-if="goods.bad_large_volume > 0">{{ goods.bad_large_volume }}{{ goods.large_unit }}</span>
              <span v-if="goods.bad_large_volume && goods.bad_least_volume"> + </span>
              <span v-if="goods.bad_least_volume > 0">{{ goods.bad_least_volume }}{{ goods.least_unit }}</span>
            </div>
          </van-cell>
          <template #right>
            <van-button square block text="删除" type="danger" @click="handleDelete(goods)" />
          </template>
        </van-swipe-cell>
      </div>
    </div>
    <div class="fixed-bottom-btn">
      <van-button v-if="afterOrder" square block :disabled="!afterOrder" @click="showActionSheet = true">添加商品</van-button>
      <van-button v-else square block @click="createAfterOrder">创建退货单</van-button>
    </div>
    <van-action-sheet v-model="showActionSheet" :actions="actions" @select="handleSelect" />
  </div>
</template>

<script>
import goodsReturnApi from '@/api/goods_return'
import myNavBar from '@/components/my-nav-bar'
export default {
  name: 'GoodsReturn',
  components: { myNavBar },
  data() {
    return {
      afterOrder: null,
      showActionSheet: false,
      actions: [
        { name: '取消订单', value: 1 },
        { name: '其它退货', value: 2 }
      ]
    }
  },
  computed: {
    totalAmount() {
      let amount = 0
      this.afterOrder.goodses.map(goods => {
        amount += Number(goods.amount)
      })
      return amount.toFixed(2)
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const params = {
        client_id: this.$route.query.client_id,
        bill_reject_id: this.$route.query.bill_reject_id
      }
      goodsReturnApi.detail(params).then(res => {
        this.afterOrder = res.data
      })
    },
    handleDelete(goods) {
      this.confirm('确定删除商品吗？').then(() => {
        const data = {
          after_order_id: this.afterOrder.id,
          after_goods_id: goods.id,
          bill_reject_id: this.$route.query.bill_reject_id
        }
        this.beginLoad()
        goodsReturnApi.delete(data).then(res => {
          this.success(res.msg)
          const index = this.afterOrder.goodses.findIndex(tgoods => tgoods.id === goods.id)
          this.afterOrder.goodses.splice(index, 1)
        })
      })
    },
    toAddView() {
      this.$router.push({
        path: '/goods-return-add',
        query: {
          client_id: this.$route.query.client_id,
          after_order_id: this.afterOrder.id,
          bill_reject_id: this.$route.query.bill_reject_id
        }
      })
    },
    toOrderView() {
      this.$router.push({
        path: '/goods-return-order',
        query: {
          client_id: this.$route.query.client_id,
          after_order_id: this.afterOrder.id,
          bill_reject_id: this.$route.query.bill_reject_id
        }
      })
    },
    handleSelect(action) {
      if (action.value === 1) {
        // 取消订单
        this.toOrderView()
      } else {
        // 其它退货
        this.toAddView()
      }
    },
    createAfterOrder() {
      const data = {
        client_id: this.$route.query.client_id,
        bill_reject_id: this.$route.query.bill_reject_id
      }
      this.beginLoad()
      goodsReturnApi.create(data).then(() => {
        this.endLoad()
        this.getDetail()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .goods-return-content {
    padding: 15px 15px 55px 15px;
    &__tips {
      padding-bottom: 10px;
      color: #A3A3A3;
    }
    &__client {
      margin-bottom: 10px;
      .van-cell {
        &__value {
          color: #ee0a24;
        }
      }
    }
    &__cell {
      background-color: #fff;
      padding: 15px;
      display: flex;
      align-items: start;
      position: relative;
      &:after {
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        right: 15px;
        bottom: 0;
        left: 15px;
        border-bottom: 0.02667rem solid #ebedf0;
        transform: scaleY(.5);
      }
      &__image {
        display: inline-block;
      }
      &__info {
        display: inline-block;
        padding-left: 10px;
        width: 220px;
        &__name {
          padding-bottom: 4px;
        }
        &__size {
          font-size: 13px;
          padding-bottom: 4px;
          color: #A3A3A3;
        }
        &__volume {
          font-size: 13px;
          padding-bottom: 4px;
          text-align: right;
          color: #A3A3A3;
        }
        &__price, &__amount {
          font-size: 13px;
          span {
            color: #ee0a24;
          }
        }
      }
    }
    .van-swipe-cell {
      .van-button {
        height: 100%;
      }
    }
    .goods-return-item {
      margin-bottom: 10px;
    }
  }
</style>
